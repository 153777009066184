import briarwood34 from "../images/Briarwood34-small.jpg"
import briarwood36 from "../images/Briarwood36-small.jpg"
import cypress from "../images/cypress-small.jpg"
import juniper from "../images/Juniper-small.jpg"
import redwood from "../images/redwoodSmall.jpg"
import sequoia from "../images/Sequoia-small.jpg"
import willow from "../images/willow-Small1.jpg"

// export const modalList = [
//     {
//         name: "Briarwood 34",
//         img: "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Brairwood%2034.jpg?alt=media&token=337eaeea-b6a4-46d9-8bd4-761ef6c3d60a",
//     },
//     {
//         name: "Briarwood 36",
//         img: briarwood36,
//     },
//     {
//         name: "Cypress",
//         img: cypress,
//     },
//     {
//         name: "Juniper",
//         img: juniper,
//     },
//     {
//         name: "Redwood",
//         img: redwood,
//     },
//     {
//         name: "Sequoia",
//         img: sequoia,
//     },
//     {
//         name: "Willow",
//         img: willow,
//     },
// ]
//willo -- "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Willow%2Fwillow-Small1.jpg?alt=media&token=966c3a93-69d9-4488-8e4e-826670577104"
//redwood -- "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Redwood%2FredwoodSmall.jpg?alt=media&token=e83cd738-3f1b-4064-bb4e-17a9237b60c2"
//cypress -- "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Cypress%2Fcypress-small.jpg?alt=media&token=4519030c-e5c4-4e15-b14e-bf0dc3c14048"
export const demoList = [
    {
        "name": "Willow",
        "image": willow,
        "blacklines": {
            "floor1": {
                "name": "Standard Main Floor Layout",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Willow%2FWillow-Blacklines_main.png?alt=media&token=49cf8a9f-c36f-4981-8457-37085555f519",
                "level": "Main"
            },
            "floor2":{
                "name": "Standard Upper Floor Layout",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Willow%2FWillow-Blacklines_upper.png?alt=media&token=da86ca0d-a8fe-4439-a70f-3e1cd8efca5b",
                "level": "Upper"
            }
        },
        "options": [
            {
                "desc": "Exterior Option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Willow%2FOptions%2FOptional%20Willow%20Prairie.jpg?alt=media&token=6c9e5603-aac8-4e67-b002-15f71a7f6eb8",
                "cost": 10250,
                "level": "Exterior"
            },
            {
                "desc": "Living Room Fireplace",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Willow%2FOptions%2FWillow%20-%20Blacklines%20-%20Optional%20Layouts_Page_1.png?alt=media&token=ee0b073b-cc14-48ab-bdf9-d5ca1a22fb0a",
                "cost": 5500,
                "level": "Main"
            },
            {
                "desc": "4 Bedroom Upper Level",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Willow%2FOptions%2FWillow%20-%20Blacklines%20-%20Optional%20Layouts_Page_2.png?alt=media&token=7398c168-7c9c-43bf-89bf-fc2c80807c89",
                "cost": 12500,
                "level": "Upper"
            },
            {
                "desc": "Laundry room Linen closet option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Willow%2FOptions%2FWillow%20-%20Blacklines%20-%20Optional%20Layouts_Page_2.png?alt=media&token=7398c168-7c9c-43bf-89bf-fc2c80807c89",
                "cost": 9750,
                "level": "Upper"
            },
        ],
        "sqFeet": 2612,
        "price": 995000
    },
    {
        "name": "Redwood",
        "image": redwood,
        "blacklines": {
            "floor1": {
                "name": "Standard Main Floor Layout",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Redwood%2FRedwood%20-%20Tudor%20-%20Blacklines_Page_1.png?alt=media&token=5f861120-b183-4c8b-b5ce-155035d3f3f5",
                "level": "Main"
            },
            "floor2":{
                "name": "Standard Lower Floor Layout",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Redwood%2FRedwood%20-%20Tudor%20-%20Blacklines_Page_2.png?alt=media&token=282ded78-eaa5-46b2-a6f2-2063e0874699",
                "level": "Lower"
            }
        },
        "options": [
            {
                "desc": "Home Office & Built-in Option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Redwood%2FOptions%2FRedwood%20-%20Tudor%20-%20Blacklines%20-%20Floor%20Plans%20Options_Page_2.png?alt=media&token=b6232fee-64b3-4056-a4ed-9d54c5ccdd66",
                "cost": 9250,
                "level": "Main"
            },
            {
                "desc": "Primary Bedroom Designer Closet Option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Redwood%2FOptions%2FRedwood%20-%20Tudor%20-%20Blacklines%20-%20Floor%20Plans%20Options_Page_2.png?alt=media&token=b6232fee-64b3-4056-a4ed-9d54c5ccdd66",
                "cost": 15750,
                "level": "Main"
            },
            {
                "desc": "Basement Development Option 1",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Redwood%2FRedwood%20-%20Tudor%20-%20Blacklines_Page_2.png?alt=media&token=282ded78-eaa5-46b2-a6f2-2063e0874699",
                "cost": 16000,
                "level": "Lower"
            },
            {
                "desc": "Basement Development Option 2",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Redwood%2FOptions%2FRedwood%20-%20Tudor%20-%20Blacklines%20-%20Floor%20Plans%20Options_Page_1.png?alt=media&token=8f784a0d-22d6-4fb6-bc5c-c8a460be3564",
                "cost": 14500,
                "level": "Lower"
            },
        ],
        "sqFeet": 2210,
        "price": 900000
    },
    {
        "name": "Cypress",
        "image": cypress,
        "blacklines": {
            "floor1": {
                "name": "Standard Main Floor Layout",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Cypress%2FCypress-Blacklines_main.png?alt=media&token=48ed1ae1-3379-4877-ab28-694e55062f71",
                "level": "Main"
            },
            "floor2":{
                "name": "Standard Upper Floor Layout",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Cypress%2FCypress-Blacklines_upper.png?alt=media&token=e78d117f-4579-4ab6-8cd1-5c303c50cdcb",
                "level": "Upper"
            }
        },
        "options": [
            {
                "desc": "Prairie Style Option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Cypress%2FOptions%2FOptional%20Prarie%20Exterior.png?alt=media&token=411774ea-f1c6-4308-b722-007fab5ccbf1",
                "cost": 8750,
                "level": "Exterior"
            },
            {
                "desc": "Main Level Spice Kitchen Option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Cypress%2FOptions%2FCypress%20-%20Blackline%20Main%20Floor%20-%20Arts%20and%20Crafts%20-%20Spice%20Kitchen%20and%20Main%20Floor%20Bedroom.png?alt=media&token=ec626a30-85df-4e5c-aeb8-f6fd4ee50ba3",
                "cost": 9550,
                "level": "Main"
            },
            {
                "desc": "Main Level Bedroom and Full Bath option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Cypress%2FOptions%2FCypress%20-%20Blackline%20Main%20Floor%20-%20Arts%20and%20Crafts%20-%20Spice%20Kitchen%20and%20Main%20Floor%20Bedroom.png?alt=media&token=ec626a30-85df-4e5c-aeb8-f6fd4ee50ba3",
                "cost": 20500,
                "level": "Main"
            },
            {
                "desc": "Great Room fireplace option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Cypress%2FOptions%2FCypress%20-%20Optional%20Layouts%20-%20Dec%2021_Page_1.png?alt=media&token=42e97823-2b06-4859-a898-42fa42bb6c61",
                "cost": 8000,
                "level": "Main"
            },
            {
                "desc": "Office cantilevel & built-in option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Cypress%2FOptions%2FCypress%20-%20Optional%20Layouts%20-%20Dec%2021_Page_1.png?alt=media&token=42e97823-2b06-4859-a898-42fa42bb6c61",
                "cost": 7500,
                "level": "Main"
            },
            {
                "desc": "Upper level forth bedroom option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Cypress%2FOptions%2FCypress%20-%20Optional%20Layouts%20-%20Dec%2021_Page_2.png?alt=media&token=76609bb7-efd1-45b3-89cb-119e60a7793a",
                "cost": 11500,
                "level": "Upper"
            },
        ],
        "sqFeet": 3110,
        "price": 1100000
    },


    {
        "name": "Briarwood 34",
        "image": briarwood34,
        "blacklines": {
            "floor1": {
                "name": "Standard Main Floor Layout",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Briarwood34%2FBriarwood%2034%20Blacklines_Page_1.png?alt=media&token=bba4049d-d2c4-42f9-a7e1-45b946a9ec87",
                "level": "Main"
            },
            "floor2":{
                "name": "Standard Upper Floor Layout",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Briarwood34%2FBriarwood%2034%20Blacklines_Page_2.png?alt=media&token=9c82b9d6-1a42-4c16-9186-42e7579ed0d9",
                "level": "Upper"
            }
        },
        "options": [
            {
                "desc": "Optional Arts & Crafts Elevation",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Briarwood34%2FOptions%2FBriarwood-Arts-Crafts-Exterior.jpeg?alt=media&token=e01f13cb-9068-48b7-9a5e-a2a55edeb974",
                "cost": 8750,
                "level": "Exterior"
            },
            {
                "desc": "Main Level Spice Kitchen Option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Briarwood34%2FOptions%2FBriarwood%2034%20Blacklines%20with%20Options.png?alt=media&token=ea12b0f8-55f8-43aa-8fe8-cf7b6270633f",
                "cost": 9550,
                "level": "Main"
            },
            {
                "desc": "Main Level Bedroom and Full Bath option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Briarwood34%2FOptions%2FBriarwood%2034%20Blacklines%20with%20Options.png?alt=media&token=ea12b0f8-55f8-43aa-8fe8-cf7b6270633f",
                "cost": 13500,
                "level": "Main"
            },
            {
                "desc": "Triple Car Garage Option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Briarwood34%2FOptions%2FBriarwood-3car-Garage.jpg?alt=media&token=f20d8c1b-b396-4583-8f1e-dc5f196a8a88",
                "cost": 15500,
                "level": "Main"
            },
        ],
        "sqFeet": 2868,
        "price": 945000
    },
    {
        "name": "Briarwood 36",
        "image": briarwood36,
        "blacklines": {
            "floor1": {
                "name": "Standard Main Floor Layout",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Brairwood36%2FBriarwood%2036%20Blacklines_Page_1.png?alt=media&token=5998c4dc-2970-4c4e-a61c-8666b01721f3",
                "level": "Main"
            },
            "floor2":{
                "name": "Standard Upper Floor Layout",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Brairwood36%2FBriarwood%2036%20Blacklines_Page_2.png?alt=media&token=2188999d-a2f3-4ce1-9f24-1a2123be9933",
                "level": "Upper"
            }
        },
        "options": [
            {
                "desc": "Prairie Style Option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Brairwood36%2FOptions%2FBriarwood-36-Prairie.jpeg?alt=media&token=59bd8e47-4d41-490b-8d64-26526d444ab4",
                "cost": 8750,
                "level": "Exterior"
            },
            {
                "desc": "Main Level Spice Kitchen Option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Brairwood36%2FOptions%2FBriarwood%2036%20Blacklines%20with%20Options.png?alt=media&token=4edcfacf-7435-4eeb-8c30-720b4579bd08",
                "cost": 9550,
                "level": "Main"
            },
            {
                "desc": "Main Level Bedroom and Full Bath option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Brairwood36%2FOptions%2FBriarwood%2036%20Blacklines%20with%20Options.png?alt=media&token=4edcfacf-7435-4eeb-8c30-720b4579bd08",
                "cost": 13500,
                "level": "Main"
            },
            {
                "desc": "Triple Car Garage Option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Brairwood36%2FOptions%2FBriarwood-36-trippleGarage.jpg?alt=media&token=404c3f35-073c-4f46-95a3-d50de74df5d2",
                "cost": 15500,
                "level": "Main"
            },
        ],
        "sqFeet": 2684,
        "price": 985000
    },
    {
        "name": "Juniper",
        "image": juniper,
        "blacklines": {
            "floor1": {
                "name": "Standard Main Floor Layout",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Juniper%2FJuniper%2036%20Main%20Floor%20Blackline.png?alt=media&token=a22a3ad0-a558-46e3-b72e-669c9a297cbc",
                "level": "Main"
            },
            "floor2":{
                "name": "",
                "image": "",
                "level": ""
            }
        },
        "options": [
            {
                "desc": "French Country Exterior Option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Juniper%2Foptions%2FJuniper-French-Country.jpg?alt=media&token=686e97dc-4287-425a-b112-020d2c39a54a",
                "cost": 8750,
                "level": "Exterior"
            },
            {
                "desc": "Main Level Bedroom and Full Bath option",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Juniper%2Foptions%2FJuniper-Full-Bed-Bath.jpg?alt=media&token=d8df9d92-700d-4591-a21d-d93bbfb0b372",
                "cost": 20500,
                "level": "Main"
            },
            {
                "desc": "Spice Kitchen Option in pantry",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Juniper%2Foptions%2FJuniper-Spice-Kitchen.jpg?alt=media&token=1fe40815-64f2-4153-80da-dc5161802d9b",
                "cost": 7500,
                "level": "Main"
            },
        ],
        "sqFeet": 1686,
        "price": 970000
    },
    {
        "name": "Sequoia",
        "image": sequoia,
        "blacklines": {
            "floor1": {
                "name": "Standard Main Floor Layout",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Sequoia%2FSequoia%20-%20Tudor%20-%20Blacklines_Page_1.png?alt=media&token=75f8e7e6-81e4-46b5-b567-94b4a5dd4574",
                "level": "Main"
            },
            "floor2":{
                "name": "Standard Upper Floor Layout",
                "image": "https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/Sequoia%2FSequoia%20-%20Tudor%20-%20Blacklines_Page_2.png?alt=media&token=cef61be8-9d02-49af-b91d-d721ebfcee55",
                "level": "Upper"
            }
        },
        "options": [
        ],
        "sqFeet": 2955,
        "price": 970000
    },
]