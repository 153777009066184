import { useNavigate } from "react-router-dom";
import lot1 from "../images/Veranda_legacy_lot_map.jpg"
import { useState } from "react";
import { lots } from "../data/lotList";
const Lots = () => {
    const [selectedLot, setSelectedLot] = useState();

    const navigate = useNavigate();
    
    const lotSelect = () => {
        if (selectedLot === undefined) {
            alert("select a lot")
        } else {
            navigate("/modals")
        }
    }
    const selectedColour = (lot, block) => {
        if (selectedLot !== undefined)
            if (lot === selectedLot.unit && block === selectedLot.section) {
                return "#E2D8BD";
            }

    }
    return (
        <div className="w-full px-6">
            <div>
                <h1 className="text-3xl font-semibold w-fit m-auto mb-5">Choose a Lot</h1>
            </div>
            <div className="w-full flex justify-evenly flex-col-reverse xl:flex-row items-center gap-2">
                <div className="flex flex-col gap-2 min-w-52">
                    {selectedLot !== undefined &&
                        <div className="w-52 h-30 border border-gray-400 px-3">
                            <h1 className="text-center">Lot Details</h1>
                            <div>
                                <p>Block: <b>{selectedLot.section}</b></p>
                                <p>Lot: <b>{selectedLot.unit}</b></p>
                                <p>Dimentions: <b>{selectedLot.dimention}</b></p>
                            </div>
                        </div>}
                    <button onClick={lotSelect} className="min-w-30 w-auto h-auto bg-v-green text-white py-1 px-2 text-lg align-middle m-auto">Select Lot</button>
                </div>
                <div className=" relative font-sans lg:text-lg md:text-base text-xs border-2 border-gray-400 rounded-md">
                    <div className="lot lot-hover absolute top-[28%] left-[20.1%] w-[7.7%] h-[6.5%] rotate-[9.5deg] text-[1em] text-center font-bold bg-v-green hover:cursor-pointer hover:bg-opacity-70 border border-black"
                        style={{ 'clipPath': "polygon(4% 0, 0 100% , 100% 93%, 97% 0)", backgroundColor: selectedColour(42, 45) }}
                        onClick={() => setSelectedLot(lots[0])}><span className="w-10">42</span></div>
                    <div className="lot absolute top-[56.3%] left-[36.15%] w-[3.4%] h-[18%] rotate-[-5.7deg] text-center font-bold bg-v-green hover:cursor-pointer hover:bg-opacity-70 border border-black"
                        style={{ 'clipPath': "polygon(0 0, 100% 0, 100% 91%, 0% 97%)", backgroundColor: selectedColour(13, 75) }}
                        onClick={() => setSelectedLot(lots[4])}><div className="h-full w-full flex justify-center items-center">13</div></div>
                    <div className="lot absolute top-[60.7%] left-[66.1%] w-[3.4%] h-[20.5%] rotate-[14.6deg] text-center font-bold bg-v-green hover:cursor-pointer hover:bg-opacity-70 border border-black"
                        style={{ 'clipPath': "polygon(0 0, 100% 0, 100% 93%, 49% 97%, 0 92%)", backgroundColor: selectedColour(22, 75) }}
                        onClick={() => setSelectedLot(lots[3])}><div className="h-full w-full flex justify-center items-center">22</div></div>
                    <div className="lot absolute top-[36.3%] left-[70.2%] w-[8%] h-[6%] rotate-[14.5deg] text-center font-bold bg-v-green hover:cursor-pointer border hover:bg-opacity-70 border-black" style={{ backgroundColor: selectedColour(22, 45) }} onClick={() => setSelectedLot(lots[2])}>22</div>
                    <div className="lot absolute top-[30.4%] left-[70.9%] w-[8%] h-[6%] rotate-[14.5deg] text-center font-bold bg-v-green hover:cursor-pointer border hover:bg-opacity-70 border-black" style={{ backgroundColor: selectedColour(21, 45) }} onClick={() => setSelectedLot(lots[1])}>21</div>
                    <img src={lot1} alt="Veranda Legacy Lot Map" className="m-auto border-none rounded-sm" />
                </div>

            </div>

        </div>
    )
}
export default Lots;