import React from 'react'

const Footer = () => {
  return (
    <div className='w-full max-w-none h-64 bg-v-green mt-20 pt-10 relative bottom-0' id='footer'>
        <div className='text-white text-center'>Veranda Info Here</div>
        <div className='text-white text-center'>Phone</div>
        <div className='text-white text-center'>Email</div>
        <div className='text-white text-center'>Socials</div>
        <div className='text-white text-center'>Address</div>
        <div className='text-white text-center'>ect.</div>
    </div>
  )
}

export default Footer