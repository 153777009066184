import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import Header from './components/header';
import Start from './pages/Start';
import Lots from './pages/Lots';
import Modals from './pages/Modals';
import Options from './pages/Options';
// import Summary from './pages/Summary';
import Footer from './components/Footer';
// import ContactInfo from './pages/ContactInfo';
import { useState, useEffect } from 'react';

function App() {
  const [modalSelection, setModalSelection] = useState({});
  const [onstart, setOnstart] = useState(true);
  const locate = useLocation().pathname;

  useEffect(() => {
    if (locate === "/") {
      setOnstart(true)
    } else {
      setOnstart(false)
    }
  }, [locate])

  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      navigate("/");
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };


  return (
    <div className='min-w-[500px] w-full min-h-screen font-garamond'>
      <Header onstart={onstart} />
      <main className='min-w-fit w-full min-h-screen'>
        <Routes>
          <Route path='/*' element={<Start />} />
          <Route path='/lots' element={<Lots />} />
          <Route path='/modals' element={<Modals setModalSelection={setModalSelection} />} />
          <Route path='/options' element={<Options data={modalSelection}/>} />
          {/* <Route path='/summary' element={<Summary modal={modalSelection} />} /> */}
          {/* <Route path='/contact' element={<ContactInfo/>} /> */}
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
