// import axiosInstance from "../axiosInstance"
// import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { demoList } from "../data/modalList";
const Modals = ({ setModalSelection }) => {
    // const [modalList, setModalList] = useState(demoList)
    const navigate = useNavigate();

    const modalSelect = (data) => {
        setModalSelection(data);
        navigate("/options");
        window.scrollTo(0,0);
    };

    // useEffect(() => {
    //     axiosInstance.
    //         get("/modal")
    //         .then((res) => {
    //             setModalList(res.data.data)
    //         })
    // }, [])

    return (
        <div className="mt-5">
            <div>
                <h2 className="text-3xl w-fit m-auto font-semibold my-3">Select Your Home Model</h2>
            </div>
            <div className="w-4/5 m-auto grid grid-cols-3 gap-10 lg:w-3/5 lg:gap-16 sm:grid-cols-3">
                {demoList.map((data, index) => (
                        <div className="w-full hover:cursor-pointer h-1/3 col-span-1 last:col-start-2"  key={index} onClick={() => (modalSelect(data))}>
                            <img src={data.image} alt={data.name} />
                            <div className="w-full h-10 flex items-center justify-center bg-v-green text-white">{data.name}</div>
                        </div>
                ))}
            </div>

        </div>
    )
}
export default Modals;