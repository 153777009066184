export const lots = [
    {
        section: 45,
        unit: 42,
        dimention: "44' x 121'"
    },
    {
       section: 45,
        unit: 21,
        dimention: "42' x 124'"
    },
    {
       section: 45,
        unit: 22,
        dimention: "42' x 123'"
    },
    {
       section: 75,
        unit: 22,
        dimention: "52' x 123'"
    },
    {
       section: 75,
        unit: 13,
        dimention: "52' x 128'"
    },
]