import {useNavigate} from "react-router-dom";

const Start = () => {
    const navigate = useNavigate();
    const startBuild = () => { 
        navigate("/lots")
    }
    return(
        <div className="w-full h-[50vh] flex justify-center items-center">
            <button onClick={startBuild} className="min-w-30 w-auto h-auto bg-v-green text-white py-1 px-2 text-lg align-middle">Start Building Your Home</button>
        </div>
    )
}

export default Start;