import React from 'react'
// import { useLocation, useNavigate } from 'react-router-dom';
import format from '../lib/formatPrice';
// import verandaLogo from "veranda/frontend/public/Logos/jpg-logo/Veranda-at-Legacy_logo_green_rgb.jpg"
//add state to params of summary----\/
const Summary = ({ modal = undefined, state, user }) => {
  // window.scrollTo(0, 0);
  // const navigate = useNavigate();

  // const { state } = useLocation();

  // console.log(state);
  // useEffect(() => {
  //   if (modal.blacklines === undefined) {
  //     return navigate("/");
  //   }
  // }, [])



  return (
    <html lang="en">
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Document</title>
        <script src="https://cdn.tailwindcss.com"></script>
      </head>
      <body className='w-[800px] m-auto'>
        <div className='h-fit p-10 w-[90%] m-auto'>
          {modal.blacklines !== undefined && <>
            <div className='flex items-center justify-start '>
              <img src={"https://firebasestorage.googleapis.com/v0/b/veranda-1f8c7.appspot.com/o/other%2FVeranda-at-Legacy_logo_green_rgb.jpg?alt=media&token=b3f10be8-e21e-4e63-b2ba-abf702bb7f1d"} alt="Veranda Logo" className="max-h-24 fixed" />
              <h1 className='text-center w-full text-4xl font-semibold mb-4'>Build Summary</h1>
            </div>

            <div className='flex  m-auto my-10' >
              <div className='w-1/2'>
                <p className='font-semibold'>Client Information</p>
                <p>Name: {user.firstName} {user.lastName}</p>
                <p>Email: {user.email}</p>
                <p>Phone: {user.phone}</p>
              </div>
              <div className='w-1/2'>
                <p className='font-semibold'>Listing Information</p>
                <p>Model Name: {modal.name}</p>
                <p>Square Feet: {modal.sqFeet}sq.Ft</p>
                <p>Base Price: {format(modal.price)}</p>
              </div>
            </div>



            <div className='border border-gray-400 m-auto text-sm font-medium'>
              {/* <div >
              <h1 className=' text-2xl font-semibold'>Model Details</h1>
              <div id='model-info' className='pl-4'>
              <p className='flex justify-between'><span className='min-w-fit'>Model Name:</span>  <span>{modal.name}</span></p>
              <p className='flex justify-between'><span className='min-w-fit'>Sq Feet:</span> <span>{modal.sqFeet}</span></p>
              <p className='flex justify-between'><span className='min-w-fit'>Price:</span> <span>{format(modal.price)}</span></p>
              </div>
              </div> */}

              <div className=''>
                <div className='grid grid-cols-3 px-4 text-lg font-semibold'>
                  <h1 className='text-left'>Level</h1>
                  <h1 className='text-center'>Option</h1>
                  <h1 className='text-right'>Cost</h1>
                </div>

                {/* Main Level Display on Summary */}
                <div id='model-info'>
                  <h1 className=' px-4 border-t border-t-gray-400'>{modal.blacklines.floor1.level} Floor</h1>
                  <div className='border-t border-t-gray-400'>
                    {state.floor1.length === 0 ? (
                      <p className='flex justify-end px-4 border-b border-b-gray-400'>
                        <span className='min-w-fit w-[45%]'>{modal.blacklines.floor1.name}</span>
                        <span className='w-1/5 text-right'>$0</span>
                      </p>
                    ) : (
                      <>
                        {state.floor1.map((elem, index) => (
                          <p className='flex justify-end px-4 border-b border-b-gray-400' key={index}>
                            <span className='min-w-fit w-[45%]'>{elem.desc}</span>
                            <span className='w-1/5 text-right'>{format(elem.cost)}</span>
                          </p>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* Upper Level Display on Summary */}
              <div id='model-info'>
                <h1 className='px-4'>{modal.blacklines.floor2.level} Floor</h1>
                <div className='border-t border-t-gray-400'>
                  {state.floor2.length === 0 ? (
                    <p className='flex justify-end px-4 border-b border-b-gray-400'>
                      <span className='min-w-fit w-[45%]'>{modal.blacklines.floor2.name}</span>
                      <span className='w-1/5 text-right'>$0</span>
                    </p>
                  ) : (
                    <>
                      {state.floor2.map((elem, index) => (
                        <p className='flex justify-end px-4 border-b border-b-gray-400' key={index}>
                          <span className='min-w-fit w-[45%]'>{elem.desc}</span>
                          <span className='w-1/5 text-right'>{format(elem.cost)}</span>
                        </p>
                      ))}
                    </>
                  )}

                </div>
              </div>
              {/* Exterior Display on Summary */}
              {state.exterior.length > 0 &&
                <div >
                  <h1 className='px-4'>Exterior</h1>
                  <div id='model-info' className='border-t border-t-gray-400'>
                    {state.exterior.map((elem, index) => (
                      <p className='flex justify-end px-4 border-b border-b-gray-400' key={index}>
                        <span className='min-w-fit w-[45%]'>{elem.desc}</span>
                        <span className='w-1/5 text-right'>{format(elem.cost)}</span>
                      </p>
                    ))}
                  </div>
                </div>}
              <div id='price-wrapper' className='border-t border-t-gray-400 h-10 text-2xl font-semibold'>
                <p className='flex justify-between px-4' >
                  <span>Total:</span>
                  <span>{format(state.addCost + modal.price)}</span>
                </p>
              </div>
            </div>
            <div className='my-4'>
              <h4 className='text-xl font-semibold mb-1'>Customized Design Request</h4>
              <p className='w-full h-fit border border-gray-400 rounded px-1 text-ellipsis'>{state.custom !== "" ? state.custom : "None"}</p>
            </div>
            <div id='QandA_Wrapper'>
              <div id='Q1' className=''>
                <h4 className='text-xl font-semibold mb-1'>(QUESTION 1 PLACEHOLDER)</h4>
                <p className='w-full min-h-5 h-fit border border-gray-400 rounded px-1'>{user.q1Answer}</p>
              </div>
              <div id='Q2' className='my-4'>
                <h4 className='text-xl font-semibold mb-1'>(QUESTION 2 PLACEHOLDER)</h4>
                <p className='w-full min-h-5 h-fit border border-gray-400 rounded px-1'>{user.q2Answer}</p>
              </div>
              <div id='Q3'>
                <h4 className='text-xl font-semibold mb-1'>(QUESTION 3 PLACEHOLDER)</h4>
                <p className='w-full min-h-5 h-fit border border-gray-400 rounded px-1'>{user.q3Answer}</p>
              </div>
            </div>
          </>}

        </div>
      </body>
    </html>
  )
}

export default Summary;